import * as i0 from '@angular/core';
import { EventEmitter, ChangeDetectorRef, Component, ChangeDetectionStrategy, Inject, Optional, Self, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@taiga-ui/cdk';
import { ALWAYS_FALSE_HANDLER, TuiMonth, TUI_FIRST_DAY, TUI_LAST_DAY, tuiWatch, TuiDestroyService, TuiMapperPipeModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_DEFAULT_MARKER_HANDLER, TuiScrollbarModule, TuiCalendarModule } from '@taiga-ui/core';
import { TUI_CALENDAR_DATE_STREAM } from '@taiga-ui/kit/tokens';
import { takeUntil } from 'rxjs/operators';
import * as i3 from 'rxjs';

/**
 * @internal
 */
class TuiPrimitiveCalendarRangeComponent {
  constructor(valueChanges, cdr, destroy$) {
    this.disabledItemHandler = ALWAYS_FALSE_HANDLER;
    this.markerHandler = TUI_DEFAULT_MARKER_HANDLER;
    this.defaultViewedMonthFirst = TuiMonth.currentLocal();
    this.defaultViewedMonthSecond = TuiMonth.currentLocal().append({
      month: 1
    });
    this.min = TUI_FIRST_DAY;
    this.max = TUI_LAST_DAY;
    this.value = null;
    this.dayClick = new EventEmitter();
    this.hoveredItem = null;
    this.userViewedMonthFirst = this.defaultViewedMonthFirst;
    this.userViewedMonthSecond = this.defaultViewedMonthSecond;
    this.monthOffset = (value, offset) => value.append({
      month: offset
    });
    if (!valueChanges) {
      return;
    }
    valueChanges.pipe(tuiWatch(cdr), takeUntil(destroy$)).subscribe(value => {
      this.value = value;
      this.updateViewedMonths();
    });
  }
  get cappedUserViewedMonthSecond() {
    return this.userViewedMonthSecond.monthBefore(this.max) ? this.userViewedMonthSecond : this.max;
  }
  get cappedUserViewedMonthFirst() {
    return this.userViewedMonthFirst.monthSameOrBefore(this.userViewedMonthSecond) ? this.userViewedMonthFirst : this.userViewedMonthSecond;
  }
  ngOnInit() {
    this.setInitialMonths();
  }
  onSectionFirstViewedMonth(month) {
    this.userViewedMonthFirst = month;
    this.userViewedMonthSecond = this.userViewedMonthFirst.append({
      month: 1
    });
  }
  onSectionSecondViewedMonth(month) {
    this.userViewedMonthSecond = month;
    this.userViewedMonthFirst = this.userViewedMonthSecond.append({
      month: -1
    });
  }
  onDayClick(day) {
    this.dayClick.emit(day);
  }
  setInitialMonths() {
    if (!this.value) {
      this.userViewedMonthSecond = this.updatedViewedMonthSecond(this.defaultViewedMonthSecond);
      this.userViewedMonthFirst = this.updatedViewedMonthFirst(this.defaultViewedMonthFirst);
    }
  }
  updatedViewedMonthSecond(month) {
    if (month.monthSameOrAfter(this.max)) {
      return this.max;
    }
    if (month.monthBefore(this.min)) {
      return this.min.append({
        month: 1
      });
    }
    return month;
  }
  updatedViewedMonthFirst(month) {
    if (month.monthSameOrAfter(this.userViewedMonthSecond)) {
      return this.userViewedMonthSecond.append({
        month: -1
      });
    }
    if (month.monthSameOrBefore(this.min)) {
      return this.min;
    }
    return month;
  }
  updateViewedMonths() {
    this.userViewedMonthFirst = this.value === null ? this.defaultViewedMonthFirst : this.value.from;
    this.userViewedMonthSecond = this.userViewedMonthFirst.append({
      month: 1
    });
  }
}
TuiPrimitiveCalendarRangeComponent.ɵfac = function TuiPrimitiveCalendarRangeComponent_Factory(t) {
  return new (t || TuiPrimitiveCalendarRangeComponent)(i0.ɵɵdirectiveInject(TUI_CALENDAR_DATE_STREAM, 8), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TuiDestroyService, 2));
};
TuiPrimitiveCalendarRangeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPrimitiveCalendarRangeComponent,
  selectors: [["tui-primitive-calendar-range"]],
  inputs: {
    disabledItemHandler: "disabledItemHandler",
    markerHandler: "markerHandler",
    defaultViewedMonthFirst: "defaultViewedMonthFirst",
    defaultViewedMonthSecond: "defaultViewedMonthSecond",
    min: "min",
    max: "max",
    value: "value"
  },
  outputs: {
    dayClick: "dayClick"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 5,
  vars: 30,
  consts: [[3, "hoveredItemChange", "dayClick", "monthChange", "disabledItemHandler", "markerHandler", "max", "maxViewedMonth", "min", "month", "showAdjacent", "value", "hoveredItem"], [1, "t-border", 3, "hoveredItemChange", "dayClick", "monthChange", "disabledItemHandler", "markerHandler", "max", "min", "minViewedMonth", "month", "showAdjacent", "value", "hoveredItem"]],
  template: function TuiPrimitiveCalendarRangeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "tui-calendar", 0);
      i0.ɵɵpipe(1, "tuiMapper");
      i0.ɵɵtwoWayListener("hoveredItemChange", function TuiPrimitiveCalendarRangeComponent_Template_tui_calendar_hoveredItemChange_0_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.hoveredItem, $event) || (ctx.hoveredItem = $event);
        return $event;
      });
      i0.ɵɵlistener("dayClick", function TuiPrimitiveCalendarRangeComponent_Template_tui_calendar_dayClick_0_listener($event) {
        return ctx.onDayClick($event);
      })("monthChange", function TuiPrimitiveCalendarRangeComponent_Template_tui_calendar_monthChange_0_listener($event) {
        return ctx.onSectionFirstViewedMonth($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(2, "tui-calendar", 1);
      i0.ɵɵpipe(3, "tuiMapper");
      i0.ɵɵpipe(4, "tuiMapper");
      i0.ɵɵtwoWayListener("hoveredItemChange", function TuiPrimitiveCalendarRangeComponent_Template_tui_calendar_hoveredItemChange_2_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.hoveredItem, $event) || (ctx.hoveredItem = $event);
        return $event;
      });
      i0.ɵɵlistener("dayClick", function TuiPrimitiveCalendarRangeComponent_Template_tui_calendar_dayClick_2_listener($event) {
        return ctx.onDayClick($event);
      })("monthChange", function TuiPrimitiveCalendarRangeComponent_Template_tui_calendar_monthChange_2_listener($event) {
        return ctx.onSectionSecondViewedMonth($event);
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("disabledItemHandler", ctx.disabledItemHandler)("markerHandler", ctx.markerHandler)("max", ctx.max)("maxViewedMonth", i0.ɵɵpipeBind3(1, 18, ctx.cappedUserViewedMonthSecond, ctx.monthOffset, -1))("min", ctx.min)("month", ctx.userViewedMonthFirst)("showAdjacent", false)("value", ctx.value);
      i0.ɵɵtwoWayProperty("hoveredItem", ctx.hoveredItem);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("disabledItemHandler", ctx.disabledItemHandler)("markerHandler", ctx.markerHandler)("max", ctx.max)("min", ctx.min)("minViewedMonth", i0.ɵɵpipeBind3(3, 22, ctx.cappedUserViewedMonthFirst, ctx.monthOffset, 1))("month", ctx.userViewedMonthSecond.monthSameOrBefore(ctx.min) ? i0.ɵɵpipeBind3(4, 26, ctx.min, ctx.monthOffset, 1) : ctx.userViewedMonthSecond)("showAdjacent", false)("value", ctx.value);
      i0.ɵɵtwoWayProperty("hoveredItem", ctx.hoveredItem);
    }
  },
  dependencies: [i1.TuiCalendarComponent, i2.TuiMapperPipe],
  styles: ["[_nghost-%COMP%]{display:flex}.t-border[_ngcontent-%COMP%]{border-left:1px solid var(--tui-base-03)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveCalendarRangeComponent, [{
    type: Component,
    args: [{
      selector: 'tui-primitive-calendar-range',
      templateUrl: './primitive-calendar-range.template.html',
      styleUrls: ['./primitive-calendar-range.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i3.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_CALENDAR_DATE_STREAM]
      }, {
        type: Optional
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i2.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, {
    disabledItemHandler: [{
      type: Input
    }],
    markerHandler: [{
      type: Input
    }],
    defaultViewedMonthFirst: [{
      type: Input
    }],
    defaultViewedMonthSecond: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    dayClick: [{
      type: Output
    }]
  });
})();

/**
 * @internal
 */
class TuiPrimitiveCalendarRangeModule {}
TuiPrimitiveCalendarRangeModule.ɵfac = function TuiPrimitiveCalendarRangeModule_Factory(t) {
  return new (t || TuiPrimitiveCalendarRangeModule)();
};
TuiPrimitiveCalendarRangeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPrimitiveCalendarRangeModule
});
TuiPrimitiveCalendarRangeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[TuiMapperPipeModule, TuiScrollbarModule, TuiCalendarModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveCalendarRangeModule, [{
    type: NgModule,
    args: [{
      imports: [TuiMapperPipeModule, TuiScrollbarModule, TuiCalendarModule],
      declarations: [TuiPrimitiveCalendarRangeComponent],
      exports: [TuiPrimitiveCalendarRangeComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPrimitiveCalendarRangeComponent, TuiPrimitiveCalendarRangeModule };
